import { createAction, createAsyncAction } from 'typesafe-actions';

// Utils
// Constants
// Actions
// Models
import {
  BookingModel,
  BasketModel,
  TripModel,
  HotelModel,
  TravelBookingModel,
  BasketItemModel,
  BookingItemModel,
  RentalModel,
  RedeemingAllowanceModel
} from '../models';
// Interfaces
import { ActionCallback } from '../interfaces';

export const setCurrentBooking = createAction(
  'checkout/SET_CURRENT_BOOKING',
  (resolve) => (booking:BookingModel) => resolve(booking)
);

export const setBasket = createAction(
  'checkout/SET_BASKET',
  (resolve) => (basket:BasketModel) => resolve(basket)
);

export const setTrips = createAction(
  'checkout/SET_TRIPS',
  (resolve) => (trips:TripModel[]) => resolve(trips)
);

export const setHotels = createAction(
  'checkout/SET_HOTELS',
  (resolve) => (hotels:HotelModel[]) => resolve(hotels)
);

export const setRentals = createAction(
  'checkout/SET_RENTALS',
  (resolve) => (rentals:RentalModel[]) => resolve(rentals)
);

export const setTravelBooking = createAction(
  'checkout/SET_TRAVEL_BOOKING',
  (resolve) => (travelBooking:TravelBookingModel) => resolve(travelBooking)
);

export const setSeatMapSelectorUrl = createAction(
  'checkout/SET_SEAT_MAP_SELECTOR_URL',
  (resolve) => (seatMapSelectorUrl:string) => resolve(seatMapSelectorUrl)
);

export const setLastWSUpdated = createAction(
  'checkout/SET_LAST_WS_UPDATED',
  (resolve) => (date:number) => resolve(date)
);

export const addBaksetItem = createAction(
  'checkout/ADD_BASKET_ITEM',
  (resolve) => (basketItem:BasketItemModel) => resolve(basketItem)
);

export const initBasket = createAction(
  'checkout/INIT_BASKET',
  (resolve) => (param:ActionCallback & { basketId:number }) => resolve(param)
);

export const initBookings = createAction(
  'checkout/INIT_BOOKINGS',
  (resolve) => (bookingId:number) => resolve({ bookingId })
);

export const addBookingItem = createAction(
  'checkout/ADD_BOOKING_ITEM',
  (resolve) => (bookingItem:BookingItemModel) => resolve(bookingItem)
);

export const setRedeemingAllowance = createAction(
  'checkout/SET_REDEEMING_ALLOWANCE',
  (resolve) => (redeemingAllowance:RedeemingAllowanceModel) => resolve(redeemingAllowance)
);

export const clearBasket = createAction('checkout/CLEAR_BASKET');

// Async actions

export const confirmBookingAsync = createAsyncAction(
  'checkout/CONFIRM_BOOKING_REQUEST',
  'checkout/CONFIRM_BOOKING_SUCCESS',
  'checkout/CONFIRM_BOOKING_FAILURE'
)<ActionCallback & {
  basketId:number,
  costCenterAllocationAttributes: any[] | undefined,
  costUnitAllocationAttributes: any[] | undefined,
  referenceCode:string,
  flightNumber?:string,
  comment:string,
  purposeOfTrip:string,
  bookingItemsAttributes:[],
  invoiceProfileId?:number,
  hotelRemarks?:string,
}, void, void>();

export const prepareBookingAsync = createAsyncAction(
  'checkout/PREPARE_BOOKING_REQUEST',
  'checkout/PREPARE_BOOKING_SUCCESS',
  'checkout/PREPARE_BOOKING_FAILURE'
)<ActionCallback & { basketId:number }, void, void>();

export const submitSupportRequestAsync = createAsyncAction(
  'checkout/SUBMIT_SUPPORT_REQUEST_REQUEST',
  'checkout/SUBMIT_SUPPORT_REQUEST_SUCCESS',
  'checkout/SUBMIT_SUPPORT_REQUEST_FAILURE'
)<ActionCallback & { bookingId:string, userComment:string, reason:string }, void, void>();
