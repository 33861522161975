import * as React from 'react';
import { connect } from 'react-redux';

// Utils
import { map, formatCents, cond } from '@src/shared/src/util/general';
import { navigate, notify, checkoutNavigationCheck, baseErrorCond } from '@toolkit/util/app';
import { t } from '@toolkit/util/i18n';
// Constants
import { ENVIRONMENT, STATUS, NOTIF_TYPE } from '@src/shared/src/const/app';
import { ROUTES, CHECKOUT_STEPS } from '@toolkit/const/app';
// Actions
// Models
import {
  BasketItemModel,
  BookingItemModel,
  BookingTransportFareModel,
} from '@src/shared/src/models';
// Interfaces
import { ConnectedRedux, IRootState } from '@src/store';
// Components
import {
  BookingInfo,
  CheckoutBookedItemsDetail,
  CheckoutFooter,
  CheckoutFooterPrepare,
  CheckoutHeader,
  CheckoutHeaderPrepare,
  CheckoutSeatMapLink,
  CheaperCombineableFlightForm,
} from '@pod/checkout/components';
import { LoaderOverlay, Modal } from '@toolkit/ui';
import { checkoutActions } from '@src/shared/src/actions';
// Styles

type Props = ConnectedRedux<IRootState> & {
  env: ENVIRONMENT;
  totalPrice: number;
  bookingItems: BookingItemModel[];
  basketItems: BasketItemModel[];
  basketStatus: STATUS;
  searchId: number;
  seatMapSelectorUrl: string;
  bookingId: string;
  possibleCheaperFlight: boolean;
  onChangeHotelRemarks?: (hotelRemarks: string) => void;
  onChangeFlightNumber?: (flightNumber:string) => void;
  requiresAllowance: boolean;
  exceedsAllowance: boolean;
  redeemingAllowance: boolean;
};

type State = {
  isPreparingBasket: boolean;
  showCheaperCombineableFlight: boolean;
  showItemsDetails: boolean;
};

class CheckoutPrepareConn extends React.PureComponent<Props, State> {
  readonly state: State = {
    isPreparingBasket: false,
    showCheaperCombineableFlight: false,
    showItemsDetails: false,
  };

  setShowCheaperCombineableFlight = (value: boolean) =>
    this.setState({ showCheaperCombineableFlight: value });

  componentDidMount() {
    checkoutNavigationCheck(this.props.basketStatus, CHECKOUT_STEPS.PREPARE, this.props.searchId);
    if (this.props.basketStatus === STATUS.PREPARE_FINISHED) {
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (
      this.props.basketStatus === STATUS.PREPARE_FINISHED &&
      this.props.possibleCheaperFlight &&
      this.props.possibleCheaperFlight !== prevProps.possibleCheaperFlight
    ) {
      notify(t('checkoutPrepare.notification.cheaperOfferIdentified'), NOTIF_TYPE.WARNING, [
        [
          `<button>${t('checkoutPrepare.button.CheckOffer')}</button>`,
          (instance: any, toast: any) => {
            this.setShowCheaperCombineableFlight(true);
            instance.hide({}, toast);
          },
        ],
      ]);
    }

    if (
      prevProps.basketStatus !== this.props.basketStatus &&
      this.props.basketStatus === STATUS.PREPARE_FINISHED
    ) {
    }
  }

  private navToPayment = () => navigate(`${ROUTES.BOOKING.PAYMENT}${this.props.searchId}`);

  private checkPriceChanges = () => {
    map((bookingItem: BookingItemModel) => {
      map((transportFare: BookingTransportFareModel) => {
        if (transportFare.previousPrice && transportFare.previousPrice !== transportFare.price) {
          // Only show the notification if the price difference is greater than 10 cents
          if (Math.abs(transportFare.price - transportFare.previousPrice) > 10) {
            notify(
              t('checkoutPrepareConn.notification.priceChange', {
                previousPrice: formatCents(transportFare.previousPrice),
                currentPrice: formatCents(transportFare.price),
              }),
              NOTIF_TYPE.WARNING,
            );
          }
        }
      }, bookingItem.transportFares);
    }, this.props.bookingItems);
  };

  submitCheaperCombinableFlightComment = (values) => {
    this.props.dispatch(
      checkoutActions.submitSupportRequestAsync.request({
        onSuccess: () => {
          notify(t('checkoutPrepareConn.notification.thankYou'), NOTIF_TYPE.SUCCESS);
          navigate(ROUTES.HOME);
        },
        onError: cond(baseErrorCond),
        bookingId: this.props.bookingId,
        userComment: values.userComment,
        reason: 'request_cheaper_ticket_for_2_oneways',
      }),
    );
    this.setShowCheaperCombineableFlight(false);
  };

  render() {
    const {
      env,
      bookingItems,
      basketStatus,
      totalPrice,
      seatMapSelectorUrl,
      requiresAllowance,
      redeemingAllowance,
      exceedsAllowance,
      bookingId,
    } = this.props;

    if (basketStatus !== STATUS.PREPARE_FINISHED && basketStatus !== STATUS.PREPARE_FAILED) {
      return (
        <LoaderOverlay
          showSpinner={true}
          title={t('checkoutConn.title.isLoadingPrepare')}
          body={t('checkoutConn.body.isLoadingPrepare')}
        />
      );
    }

    // Check if the fare prices have changed
    this.checkPriceChanges();

    return (
      <React.Fragment>
        <CheckoutHeader isReverse={true}>
          <CheckoutHeaderPrepare
            requiresAllowance={requiresAllowance}
            exceedsAllowance={exceedsAllowance}
            bookingId={bookingId}
            navToPayment={this.navToPayment}
          />
        </CheckoutHeader>
        <CheckoutBookedItemsDetail
          env={env}
          showItemsDetails={this.state.showItemsDetails}
          showHotelRemarksForm={true}
          onChangeHotelRemarks={this.props.onChangeHotelRemarks}
          showRentalForm={true}
          onChangeFlightNumber={this.props.onChangeFlightNumber}
        />
        <CheckoutSeatMapLink
          seatMapSelectorUrl={seatMapSelectorUrl}
          showSelectionWarning={redeemingAllowance}
        />
        <BookingInfo bookingItems={bookingItems} env={env} />
        <CheckoutFooter totalPrice={totalPrice}>
          <CheckoutFooterPrepare
            requiresAllowance={requiresAllowance}
            exceedsAllowance={exceedsAllowance}
            bookingId={bookingId}
            navToPayment={this.navToPayment}
          />
        </CheckoutFooter>
        <Modal
          isOpen={this.state.showCheaperCombineableFlight}
          onClose={() => this.setShowCheaperCombineableFlight(false)}>
          <CheaperCombineableFlightForm onSubmit={this.submitCheaperCombinableFlightComment} />
        </Modal>
      </React.Fragment>
    );
  }
}

export default connect()(CheckoutPrepareConn);
