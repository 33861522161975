import * as React from 'react';
import { connect } from 'react-redux';

// Utils
import {
  cond,
  isNilOrEmpty,
  map,
  pipe,
  uniq,
  verifyProperty,
  pluck,
  values,
  filter,
  concat,
  pathOr,
  defaultTo,
  isEmpty,
  isNotNilOrEmpty,
  isNil,
} from '@src/shared/src/util/general';
import { selectors } from '@src/shared/src';
import { adminUserSelectors } from '@src/shared/src/selectors';
import { getMainLegOperators, getTripsVechiles } from '@src/shared/src/util/trips';
import { navigate, checkoutNavigationCheck, timeoutErrorCond, translatedErrorCond } from '@toolkit/util/app';
import { t } from '@toolkit/util/i18n';
// Constants
import { STATUS } from '@src/shared/src/const/app';
import { ROUTES, CHECKOUT_STEPS } from '@toolkit/const/app';
// Actions
import { checkoutActions } from '@src/shared/src/actions';
import { UIActions } from '@pod/ui/reducer';
// Models
import { PolicyViolationModel, TripModel, UserModel, PassengerModel, BookingModel } from '@src/shared/src/models';
// Interfaces
import { ConnectedRedux, IRootState } from '@src/store';
// Components
import {
  CheckoutHeader,
  CheckoutHeaderPayment,
  CheckoutPaymentForm,
  CheckoutPaymentPassengersInfo,
} from '@pod/checkout/components';
import { LoaderOverlay } from '@toolkit/ui';
import { generateAction } from '@toolkit/util/state-utils/action.util';
// Styles

type Props = ConnectedRedux<IRootState> & {
  passengers: PassengerModel[];
  currentUser: UserModel;
  outwardTrip: TripModel;
  inboundTrip: TripModel;
  basketStatus: STATUS;
  searchId: number;
  totalPrice: number;
  basketId: number;
  policyViolations: PolicyViolationModel[];
  hotelRemarks: string;
  flightNumber: string;
  isConfirmingBooking: boolean;
  firstPassenger: PassengerModel;
  currentBooking: BookingModel;
};
class CheckoutPaymentConn extends React.PureComponent<Props> {
  componentDidMount() {
    checkoutNavigationCheck(this.props.basketStatus, CHECKOUT_STEPS.PAYMENT, this.props.searchId);
  }

  private getBookingOperators = (outwardTrip: TripModel, inboundTrip: TripModel) => {
    const outwardOperators = !isNilOrEmpty(outwardTrip)
      ? getMainLegOperators(outwardTrip.legs)
      : [];
    const inboundOperators = !isNilOrEmpty(inboundTrip)
      ? getMainLegOperators(inboundTrip.legs)
      : [];
    return [...outwardOperators, ...inboundOperators];
  };

  private getBookingVehicles = (outwardTrip: TripModel, inboundTrip: TripModel) => {
    const trips: TripModel[] = [];
    if (!isNilOrEmpty(outwardTrip)) {
      trips.push(outwardTrip);
    }
    if (!isNilOrEmpty(inboundTrip)) {
      trips.push(inboundTrip);
    }
    return getTripsVechiles(trips);
  };

  private parsePolicyViolationForResponse = (polViol: any) => {
    const bookingItemAttributes = [];
    const bookingItemIds = uniq(values(pluck('bookingItemId')(polViol)));
    bookingItemIds.map((bid: number) => {
      const policyViolationsForBooking = filter((polVio: any) => polVio.bookingItemId === bid)(
        polViol,
      );
      const polViolationsArr = [];
      map((pv: any) => {
        polViolationsArr.push({
          id: pv.policyId,
          justification: pv.justification,
        });
      }, policyViolationsForBooking);
      bookingItemAttributes.push({
        id: bid,
        policy_violations_attributes: polViolationsArr,
      });
    });
    return bookingItemAttributes as [];
  };

  private onConfirmBasket = async formValues => {
    const costCenterAllocations = pipe(
      defaultTo([]),
      map(costCenter => ({
        entity_id: costCenter.selectValue,
        percent: costCenter.percentageValue,
      })),
    )(formValues.costCenters);

    const costUnitAllocations = pipe(
      defaultTo([]),
      map(costUnit => ({
        entity_id: costUnit.selectValue,
        percent: costUnit.percentageValue,
      })),
    )(formValues.costUnits);

    const policyViolations = pipe(
      pathOr([], ['policyViolations']),
      filter(isNotNilOrEmpty),
      map(val => JSON.parse(val)),
    )(formValues);

    this.props.dispatch(generateAction<boolean>(UIActions.isConfirmingBooking, true));

    this.props.dispatch(
      checkoutActions.confirmBookingAsync.request({
        basketId: this.props.basketId,
        costCenterAllocationAttributes: isEmpty(costCenterAllocations)
          ? undefined
          : costCenterAllocations,
        costUnitAllocationAttributes: isEmpty(costUnitAllocations)
          ? undefined
          : costUnitAllocations,
        referenceCode: verifyProperty('', formValues, ['referenceCode']),
        purposeOfTrip: verifyProperty('', formValues, ['purposeOfTrip']),
        flightNumber: this.props.flightNumber,
        comment: verifyProperty('', formValues, ['comment']),
        invoiceProfileId: verifyProperty(null, formValues, ['accountingInvoiceProfile']),
        hotelRemarks: this.props.hotelRemarks,
        bookingItemsAttributes: isNilOrEmpty(policyViolations)
          ? []
          : this.parsePolicyViolationForResponse(policyViolations),
        onSuccess: () => {
          this.props.dispatch(generateAction<boolean>(UIActions.isConfirmingBooking, false));
          navigate(`${ROUTES.BOOKING.CONFIRM}${this.props.searchId}`);
        },
        onError: err => {
          this.setState({ isConfirmButtonLoading: false });
          this.props.dispatch(generateAction<boolean>(UIActions.isConfirmingBooking, false));
          return cond([timeoutErrorCond, translatedErrorCond])(err);
        },
      }),
    );
  };

  getInvoiceProfiles = (firstPassenger: PassengerModel, currentUser: UserModel) =>
    uniq(concat(firstPassenger.accountingInvoiceProfiles, currentUser.accountingInvoiceProfiles));

  render() {
    const {
      outwardTrip,
      inboundTrip,
      passengers,
      searchId,
      totalPrice,
      policyViolations,
      currentUser,
      basketStatus,
      firstPassenger,
      currentBooking,
    } = this.props;

    if (basketStatus !== STATUS.PREPARE_FINISHED || isNil(currentBooking)) {
      return (
        <LoaderOverlay
          showSpinner={true}
          title={t('checkoutConn.title.isLoadingPrepare')}
          body={t('checkoutConn.body.isLoadingPrepare')}
        />
      );
    }

    return (
      <React.Fragment>
        <CheckoutHeader>
          <CheckoutHeaderPayment
            navToPrepare={() => navigate(`${ROUTES.BOOKING.PREPARE}${searchId}`)}
          />
        </CheckoutHeader>
        <CheckoutPaymentPassengersInfo passengers={passengers} />
        <CheckoutPaymentForm
          onSubmit={this.onConfirmBasket}
          policyViolations={policyViolations}
          accountingInvoiceProfiles={this.getInvoiceProfiles(firstPassenger, currentUser)}
          totalPrice={totalPrice}
          operators={this.getBookingOperators(outwardTrip, inboundTrip)}
          vehicles={this.getBookingVehicles(outwardTrip, inboundTrip)}
          isConfirmingBooking={this.props.isConfirmingBooking}
        />
      </React.Fragment>
    );
  }
}
const mapState = (state: IRootState) => ({
  outwardTrip: selectors.checkout.outwardTrip(state.checkout),
  inboundTrip: selectors.checkout.inboundTrip(state.checkout),
  currentUser: adminUserSelectors.currentUser(state.adminUser),
  passengers: selectors.search.searchPassengers(state.search),
  firstPassenger: selectors.search.firstPassenger(state.search),
  isConfirmingBooking: state.ui.isConfirmingBooking,
  currentBooking: state.checkout.currentBooking,
});
export default connect(mapState)(CheckoutPaymentConn);
